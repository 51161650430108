// Menu.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css'; // Custom styles for your menu

const Menu = () => {
  return (
    <nav>
    <div className="navbar-container">
      <ul class="nav-menu">
        <li class="nav-item">
          <Link to="/" className="nav-links">الرئيسيه</Link>
        </li>
        <li class="nav-item">
          <Link to="/about" className="nav-links">من نحن</Link>
        </li>
        <li class="nav-item">
          <Link to="/Gallery" className="nav-links">اعمالنا</Link>
        </li>
        
        <li class="nav-item">
          <a href="#" className="nav-links">اتصل بنا</a>
        </li>
        <li class="nav-item">
          <a href="#" className="nav-links">المدونه</a>
        </li>
      </ul>
      <div class="nav-icon">
        <i class="fas fa-bars"></i>
      </div>
    </div>
  </nav>

  );
};

export default Menu;
