import React from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';


class Service extends React.Component{
    services=this.props.data.map((service)=>{
        return <div className="col-md-4 mb-3">
                       
        <div className="card"  >
        <Link to={"/service/"+ service.name} className="nav-links"><img src={service.image} className="card-img-top" title={service.name} alt={ service.name } /></Link>
  <div class="card-body">
    <h5 class="card-title">{ service.name }</h5>
    <p class="card-text">{ parse(service.content.intro )}</p>
  </div>
</div>

      </div>
    });
    render (){
        return <>
                    <div className="text-center"><h2>خـدمـاتـنا</h2></div>
                  <div className="row">
                                      
                      { this.services }
                
                  </div>   

             </>
    }
}

export default Service;