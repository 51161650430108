import React from "react";
import { useParams } from "react-router-dom";
import services from "./../../api/services.json";
import parse from 'html-react-parser';

function Service() {
  let params = useParams();
  let query = params.service; // The search query from the route

  // Assuming services is an array of service objects
  let items = services;

  if (Array.isArray(items)) {
    const filteredItems = items.filter((item) => {
      return (
        item.name &&
        item.name.toLowerCase().includes(query.toLowerCase())
      );
    });

    return (
      <>
        {filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <React.Fragment key={item.id}>
              <div className="text-center page-title">
                <h2>{item.name}</h2>

              </div>
              <div className="row">
                <p>{parse(item.content.intro)}</p>
                <p>{parse(item.content.damages)}</p>
                <p>{parse(item.content.why_us)}</p>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div>No items match your search criteria.</div>
        )}
      </>
    );
  } else {
    return <>No items to display</>;
  }
}

export default Service;
