import React from "react";

class About extends React.Component{
    render(){
        return <div>
            <div className="page-title align-middle"><h2>من نحن - شركة البيت السعودي</h2></div>
            <p className="p-3 align-middle">
شركة البيت السعودي هي الشركة الرائدة في مكافحة الحشرات والخدمات المنزلية في المملكة العربية السعودية. تأسست الشركة لتوفير بيئة صحية ونظيفة للمواطنين والمقيمين. نحن نؤمن بأهمية الحفاظ على منازلنا ومكاتبنا خالية من الحشرات والآفات التي قد تسبب الأمراض وتلحق الأضرار بالممتلكات.

خدماتنا
تقدم شركة البيت السعودي مجموعة واسعة من الخدمات المتميزة التي تلبي احتياجات العملاء وتساعدهم في الحفاظ على بيئة صحية وآمنة. تشمل خدماتنا:

مكافحة الحشرات:

مكافحة الصراصير: نقدم حلولاً فعّالة لمكافحة الصراصير باستخدام أحدث التقنيات والمواد الآمنة.
مكافحة النمل الأبيض: نوفر خدمات متخصصة لمكافحة النمل الأبيض والحفاظ على الممتلكات من أضراره.
مكافحة الفئران: نوفر خدمات متكاملة لمكافحة الفئران والتخلص منها بشكل نهائي.
تنظيف وتطهير المنازل:

تنظيف المنازل: نقدم خدمات تنظيف شاملة لجميع أنحاء المنزل باستخدام مواد تنظيف صديقة للبيئة.
تطهير المنازل: نوفر خدمات تطهير متقدمة لضمان بيئة خالية من الجراثيم والبكتيريا.
خدمات الوقاية:

تركيب الحواجز الوقائية: نقدم حلولاً مبتكرة لتركيب حواجز وقائية تمنع دخول الحشرات والآفات.
برامج الوقاية الدورية: نوفر برامج وقاية دورية لضمان استمرار حماية المنازل والمكاتب من الحشرات.
أهم إنجازاتنا
حققت شركة البيت السعودي العديد من الإنجازات التي نفتخر بها، ومن أبرزها:

تقديم خدمات متميزة: على مر السنوات، قدمت الشركة خدمات عالية الجودة لآلاف العملاء الذين أعربوا عن رضاهم الكامل.
توسيع نطاق الخدمات: تمكنت الشركة من توسيع نطاق خدماتها لتشمل مختلف مناطق المملكة، مما يعكس ثقة العملاء الكبيرة فينا.
الابتكار في الحلول: نحن دائماً نسعى لتقديم حلول مبتكرة ومستدامة لمكافحة الحشرات والآفات، باستخدام أحدث التقنيات والمواد الآمنة.
الالتزام بالمعايير العالمية: تلتزم الشركة بتطبيق أعلى المعايير العالمية في جميع خدماتها لضمان جودة الخدمة ورضا العملاء.
تواصل معنا
نحن في شركة البيت السعودي نسعى دائماً لتلبية احتياجات عملائنا وتقديم أفضل الحلول لضمان بيئة صحية ونظيفة. لا تترددوا في الاتصال بنا للحصول على استشارة مجانية أو لتحديد موعد لخدماتنا المتميزة.

</p>
        </div>
    }
}

export default About;
