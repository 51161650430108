import './App.css';
// Importing bootstrap framework
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/home/Home';
import Footer from './component/Footer/Footer';
import TopBar from './component/TopBar/TopBar';
import Header from './component/Header/Header';
import About from './pages/About/About';
import Service from './pages/Services/Service';
import Gallery from './pages/Gallery/Gallery';
function App() {
  return (
    <div className="App ">
      <Router>
        <section className='container-fluid p-0'>
          <TopBar />
          <Header /> 
          <div className='container-fluid'>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service/:service" element={<Service />} />
            <Route path="/Gallery" element={<Gallery />} />
            
          </Routes>
          </div>
          <Footer />    
        </section>
      </Router>
    </div>
  );
}

export default App;
