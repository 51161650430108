import React from "react";
import Menu from "../headerMenu/Menu";
import './header.css';
class Header extends React.Component{
    render(){
        return    <header className="App-header">
                        <div className="logo">
                           <img src='/images/logo.jpeg' width='100'/>    
                        </div>
                        <Menu />
                    </header>
    }
}

export default Header ;
