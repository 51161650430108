import LightGallery from 'lightgallery/react';

// Import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of CSS
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// Import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

function Gallery() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };

    return (
        <div className="container">
            
                <LightGallery
                    onInit={onInit}
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    className="row">
                    <div className="col-md-4" >
                        <a href="./images/hamam/البيت-السعودي-طارد-حمام.jfif">
                            <img className="img-rounded w-100" data-sub-html="طارد حمام" src="./images/hamam/البيت-السعودي-طارد-حمام.jfif" />
                        </a>
                    </div>
                    
                    <div className="col-md-4">
                        <a href="./images/hamam/-السعوديه-البيت-السعودي-طارد-حمام.jfif">
                            <img className="img-responsive w-100" alt="طارد حمام" src="./images/hamam/-السعوديه-البيت-السعودي-طارد-حمام.jfif" />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a href="./images/hamam/-السعوديه-البيت-السعودي-طارد-حمام.jfif">
                            <img className="img-responsive w-100" alt="طارد حمام" src="./images/hamam/-السعوديه-البيت-السعودي-طارد-حمام.jfif" />
                        </a>
                    </div>
                </LightGallery>
            </div>
        
    );
}

export default Gallery;
