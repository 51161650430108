// Menu.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css'; // Custom styles for your menu

const Menu = () => {
  return (
    <nav>
    <div className="navbar-container">
      <ul class="nav-menu">
        <li class="nav-item">
          <Link to="./service/خدمة%20طرد%20الحمام" className="nav-links">خدمة طرد الحمام</Link>
        </li>
        <li class="nav-item">
          <Link to="./service/رش%20مبيدات%20الحشرات%20لمكافحة%20الحشرات" className="nav-links">رش مبيدات الحشرات</Link>
        </li>
        <li class="nav-item">
          <Link to="./service/تنظيف%20مكيفات%20سبليت" className="nav-links">تنظيف مكيفات سبليت</Link>
        </li>
        
        <li class="nav-item">
          <Link to="./service/تنظيف%20وغسل%20الخزانات" className="nav-links">تنظيف وغسل الخزانات</Link>
        </li>
        <li class="nav-item">
          <Link to="./service/مغسلة%20سيارات%20متنقلة" className="nav-links">مغسلة سيارات متنقلة</Link>
        </li>
        <li class="nav-item">
          <Link to="./service/خدمة%20تنظيف%20المنازل" className="nav-links">خدمة تنظيف المنازل</Link>
        </li>

      </ul>
      <div class="nav-icon">
        <i class="fas fa-bars"></i>
      </div>
    </div>
  </nav>

  );
};

export default Menu;
