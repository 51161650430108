import React from "react";
import Menu from "../Menu/Menu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Import the phone icon

import './TopBar.css';

class TopBar extends React.Component{
    render(){
        return (
            <header className="App-top pl-3">
                <Menu />
                <div className="contact">
                                  <a href='tel:0538534404'>0538534404 <FontAwesomeIcon icon={faPhone} /></a>
                </div>
                <div className="social-media">
                    <a href="https://facebook.com" className="social-icon">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://twitter.com" className="social-icon">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://instagram.com" className="social-icon">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>
            </header>
        );
    }
}

export default TopBar;
