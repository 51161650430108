
import React from "react";
import'./Footer.css';
class Footer extends React.Component{

    render(){
     return    <footer className="App-footer mt-1">
                  <p>Powerd By D3S</p>
               </footer>
}
    
}
export default Footer;